import React, { Component } from 'react'

export default class About extends Component {
	render() {
		return (
			<div className='uk-container'>
				<div className='banner'>
					<p className='uk-text-meta'>Things are yet to come here,</p>
					<br />
					<p className='uk-text-meta'>but I assure you will know everything about me </p>
				</div>
			</div>
		)
	}
}
