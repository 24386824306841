import React from 'react'
import Logo from './Logo'
import { Link } from 'react-router-dom'

const Navbar = ({ config }) => {
	return (
		<div class='uk-container'>
			<nav class='uk-navbar uk-navbar-transparent' uk-navbar>
				<div class='uk-navbar-left'>
					<Link to='/'>
						<Logo style={{ height: 50, width: 50 }} />
					</Link>
				</div>
				<div class='uk-navbar-right'>
					<ul class='uk-navbar-nav'>
						{config.map(route => (
							<li class=''>
								<Link to={route.path}>{route.name}</Link>
							</li>
						))}
					</ul>
				</div>
			</nav>
		</div>
	)
}

export default Navbar
