import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const qoute = `A user interface is like a joke. If you have to explain it, it’s not that good.  😉`

const Grid = styled.div`
	display: flex;
	flex: row wrap;
`

const Content = styled.div`
	flex-basis: ${props => `${props.percent}%` || '50%'};
	max-width: 100%;
	padding: 2rem;
	background: #e7e7e7;
`

class Home extends Component {
	state = { loading: true }
	render() {
		return (
			<div className='uk-container'>
				<div className='banner'>
					<div>
						<p className='uk-text-meta'>{qoute}</p>
						<p className='uk-text-meta'>
							Just kidding. Go to{' '}
							<Link style={{ color: 'blue' }} to='/about'>
								about
							</Link>{' '}
							to know more about me.
						</p>
					</div>
				</div>
				{/* <Grid>
					<Content percent='40'></Content>
					<Content percent='60'></Content>
				</Grid> */}
			</div>
		)
	}
}

export default Home
