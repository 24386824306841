import React from 'react'
import Home from './pages/Home'
import About from './pages/About'

import Navbar from './components/Navbar'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Blog from './pages/Blog'

const routes = [
	// { name: 'Home', path: '/' },
	{ name: 'About', path: '/about' },
	{ name: 'Blog', path: '/blog' },
]

const App = () => {
	return (
		<Router>
			<Navbar config={routes} />
			<Switch>
				<Route path='/' exact>
					<Home />
				</Route>
				<Route path='/about' exact>
					<About />
				</Route>
				<Route path='/blog' exact>
					<Blog />
				</Route>
				<Route path='/blog/:path'>
					<Blog />
				</Route>
			</Switch>
		</Router>
	)
}

export default App
