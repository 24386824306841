import React from 'react'

const Logo = ({ style }) => {
	return (
		<svg width='378' height='372' viewBox='0 0 378 372' fill='none' xmlns='http://www.w3.org/2000/svg' {...style}>
			<rect width='366' height='366' fill='#262222' />
			<path
				d='M84.1484 50.625L107 121.852L129.781 50.625H162.266V153H137.516V129.094L139.906 80.1562L115.156 153H98.8438L74.0234 80.0859L76.4141 129.094V153H51.7344V50.625H84.1484ZM251.914 116.859H238.555V153H213.875V50.625H254.164C266.305 50.625 275.797 53.3203 282.641 58.7109C289.484 64.1016 292.906 71.7188 292.906 81.5625C292.906 88.6875 291.453 94.5938 288.547 99.2812C285.688 103.969 281.188 107.766 275.047 110.672L296.422 151.945V153H269.984L251.914 116.859ZM238.555 97.8047H254.164C258.852 97.8047 262.367 96.5859 264.711 94.1484C267.102 91.6641 268.297 88.2188 268.297 83.8125C268.297 79.4062 267.102 75.9609 264.711 73.4766C262.32 70.9453 258.805 69.6797 254.164 69.6797H238.555V97.8047ZM137.234 322H112.695L76.4141 258.297V322H51.7344V219.625H76.4141L112.625 283.328V219.625H137.234V322ZM249.242 303.016H292.203V322H224.562V219.625H249.242V303.016Z'
				fill='#E8E8E8'
			/>
		</svg>
	)
}

export default Logo
