import React from 'react'
import { useParams } from 'react-router-dom'

const Blog = () => {
	const { path = '' } = useParams()
	console.log(path)
	return (
		<div className='banner'>
			<iframe title='Blog' width='100%' height='100%' src={`https://medium.com/@mrinalraj/${path}`} />
		</div>
	)
}

export default Blog
